import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { SelectState } from '@vg-constellation/angular-13/select';
import { content } from '../../../content/content';

export interface LinkOption {
  optionLabel: string;
  optionUrl: string;
  key: string;
}

export const urlPattern = new RegExp(
  '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$',
  'i',
); // fragment locator

export const vgUrlPattern = /\.vanguard\.com(?::{0,1}\d{0,5})/;

@Component({
  selector: 'app-tetriary-navigation',
  templateUrl: 'tetriary-navigation.component.html',
  styleUrls: ['tetriary-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TetriaryNavigationComponent {
  content = content;

  private _options: LinkOption[];

  @Input()
  set options(value: LinkOption[]) {
    this._options = value.filter((option) => this.isValidUrl(option, urlPattern, vgUrlPattern));
  }

  get options() {
    return this._options;
  }

  @Input()
  activeLink: string;

  constructor(@Inject(DOCUMENT) private readonly document: Document) {}

  dropdownChanged($event: SelectState): void {
    const url = encodeURI($event.value);
    if (url !== 'undefined') {
      this.document.location.href = url;
    }
  }

  private isValidUrl(option: LinkOption, urlPattern, vgUrlPattern): boolean {
    const url = option.optionUrl;
    return  !url || (urlPattern.test(url) && vgUrlPattern.test(url));
  }
}
