import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class GatekeeperService {
  constructor(private readonly http: HttpClient, private readonly router: Router) {}

  public isFeatureSwitchedOn(featureId: string): Observable<boolean> {
    const url =  `${environment.STATEMENTS.BASE_URL}${environment.STATEMENTS.GATEKEEPER_URL}/${featureId}`;
    return this.http
      .get<any>(url)
      .pipe(
        map((isToggle) => {
          return isToggle;
        }),
        catchError(() => {
          return of(false);
        }),
      );
  }

  public redirectToModernizedApp(): void {
    this.router.navigate(['']);
  }

  getGatekeeperFLag() {
    this.isFeatureSwitchedOn(environment.GATEKEEPER_FEATURE_ID).toPromise()
      .then((response: boolean) => {
        if (response) {
          // redirect to legacy if gatekeeper is set to true
          document.location.href = environment.LEGACY_URL+'?redirect=true';
        }
        else {
          this.redirectToModernizedApp();
        }
      }).catch((err)=>{
        console.log('Gatekeeper catch block', err);
      })
  }
}
