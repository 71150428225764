export class Log {
    constructor(public level: LoggerLevel, public message: LogMessage) { }
}

export interface LogMessage {
    //General - Required
    app: string;
    message: string;
    // feature?: string;
    logCode?: string;
    // poid?: string;

    //General - Optional
    // build?: string | number;
    // error?: Error | string;
    // stackTrace?: string;

    //HTTP related
    // method?: 'GET' | 'POST';
    // duration?: number;
    // statusCode?: number;
    // statusText?: string;
    // url?: string | [string];
    // requestBody?: string;
    // headers?: string;
}

export enum LoggerLevel {
    INFO = 'info',
    WARN = 'warn',
    ERROR = 'error',
    LOG = 'log'
}
