<div class="d-none d-lg-block">
  <div role="navigation" aria-label="Tertiary navigation" class="tetriary-navigation-list__container">
    <ng-container *ngFor="let option of options">
      <div *ngIf="!option?.optionUrl">
          <a c11n-link color="primary" href="#" [attr.aria-current]="true" (click)="$event.preventDefault()" class="c11n-link c11n-link--regular c11n-link--secondary c11n-text-md">
            <span class="c11n-link__text tetriary-navigation-list__link">
            {{option.optionLabel}}
          </span>
        </a>
      </div>
      <div *ngIf="option?.optionUrl">
        <a c11n-link color="secondary" [href]="option.optionUrl" class='c11n-link c11n-link--regular c11n-link--secondary c11n-text-md'>
          <span class="c11n-link__content">
            <span class="c11n-link__text">
              {{option.optionLabel}}
            </span>
          </span>
        </a>
      </div>
    </ng-container>
  </div>
</div>

<div class="d-block d-lg-none">
  <div class="tetriary-navigation-dropdown__container row d-inline-block">
    <div class="tetriary-navigation__title c11n-text-md col-4 float-left-class mt-3 ps-2">
      <span for="tetriary-navigation-dropdown_id" class="tetriary-navigation__title-text">Show:</span>
    </div>
    <div class="tetriary-navigation-dropdown__content col-8 float-right-class">
      <c11n-select hintText="" (stateChange)="dropdownChanged($event)" labelText="" class="hide-select-label-and-error">
        <select c11nSelectInput id="tetriary-navigation-dropdown_id" class="c11n-select__box__select" aria-label="select show dropdown">
          <option *ngFor="let option of options" [value]="option.optionUrl" [selected]="!option.optionUrl">
            {{option.optionLabel}}
          </option>
        </select>
      </c11n-select>
    </div>
  </div>
</div>
