export enum RuntimeEnvironments {
    DEV = 'DEV',
    SAT = 'SAT',
    PRF = 'PRF',
    PROD = 'PROD',
  }
  export enum MSAEnvironments {
    PERFORMANCE = 'performance',
    DEVELOPMENT = 'development',
    PRODUCTION = 'production',
  }
