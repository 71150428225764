import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CaasService } from './services/caas/caas.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit{
  public constructor(private readonly titleService: Title, private readonly caasService:CaasService){}

  ngOnInit(){
    const response = this.caasService.getCaasData();
    this.titleService.setTitle(response.Data.statements.content.browserTitle || '');
  }
}
