import { ChangeDetectorRef,Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { WindowRef } from '@vanguard/secure-site-components-lib';
import { gql } from 'apollo-angular';
import { content } from 'content/content';

import { StatementService } from '../../services/statement/statement.service';
import { ModalDialogComponent } from '@vg-constellation/angular-13/modal-dialog';
import { Stmtobject, Selectedvalueobject, StmtResponse } from '../../models/statements.model';
import { LoggerService } from '../../services/logger/logger.service';
import { StatementsContent, Dropdownobject } from '../../models/caas.model';
import { CaasService } from '../../services/caas/caas.service';
import { Subscription } from 'rxjs';
import PKG from '../../../../package.json';
import moment from 'moment';
import { environment } from 'environments/environment';
import { DropdownMenuData } from '@vg-constellation/angular-13/dropdown';

const ALL_MONTHS = 'All months';
const GRADIENT_LEFT = 'gradient-left';
const SCROLLVALUE = 100;

@Component({
  selector: 'app-statements',
  templateUrl: './statements.component.html',
  styleUrls: ['./statements.component.scss']
})

export class StatementsComponent implements OnInit {
  content = content;
  errorBannerConfig = {
    size: 'medium',
    heading: '',
    body: '',
    closable: false,
    collapsible: false,
    type: 'error',
  };
  shouldShowErrorBanner = false;
  viewDropdownContent: any = [];
  tetriaryNavigationOptions: Array<Dropdownobject>;

  query = gql`
    query {
      client {
        preferredName
        lastLoginTimestamp
      }
      accountsInfo {
        totals {
          totalBalance
          totalBalanceAsOfDate
        }
      }
      unreadSecureMessageCount
    }
  `;
  showOptOutButton = false;
  showPlaceholder = 0;
  selectLabel = 'Tax Form';
  statementsList: Stmtobject[] = [];
  masterStatementsList: Stmtobject[] = [];
  errorResponse = {};
  errorStateYear = false;
  errorStateMonth = false;
  statementFetchNoError = true;
  errorTextYear: string;
  errorTextMonth: string;
  statementFetchErrorText: string;
  hidePrev = false;
  hideNext = false;
  currentPage = 1;
  primaryvariant = 'primary';
  secondaryvariant = 'secondary'
  years: string[] = [new Date().getFullYear().toString()];
  months: string[] = [];
  selectedValues: Selectedvalueobject = {
    year: new Date().getFullYear().toString()
  };
  selectedMonth = '';
  modelheading: string;
  tableheading: string = content.statementsContent.tableSection.accountColumnLabel;
  showpdfdataresponsive = true;
  showpdfdata = true;
  showaccountdata = true;

  caasSubscription: Subscription;
  currentContent: Array<string> = new Array<string>();
  statementsContent: StatementsContent;
  public isLoaderIndicator = true;
  contentBody: string;
  shouldShowErrorBannerForNoData = false;
  errorPdfDownload = 'The information is currently unavailable';

  showMoreIndex;
  noOfRecordsInTable = 20;
  hideShowMore = true;
  filterOn = false;
  filteredMasterStatementList: Stmtobject[] = [];

  dateSortingState = 'descending';
  accountSortingState = 'none';
  month = '';
  tableOverlayShow = false;
  showMoreKeyboardFlag = false;
  dropdownMenuOptions: DropdownMenuData;

  downloadLinkLabel = environment.downloadAccountLink.label
  downloadLinkUrl = environment.downloadAccountLink.url

  @ViewChild('contentElement') elRef: ElementRef;
  @ViewChild(ModalDialogComponent) modal: ModalDialogComponent;
  @ViewChild('errorModal') errorModal: ModalDialogComponent;
  constructor(
    private readonly statementService: StatementService,
    private readonly loggerService: LoggerService,
    private readonly caasService: CaasService,
    private readonly changeDetector: ChangeDetectorRef,
    public windowRef: WindowRef
  ) { }

  async ngOnInit() {
    this.getCaasData();
    this.retrieveData();
    this.dropdownMenuOptions = { groups: [{ items: this.viewDropdownContent }] };
  }

  async retrieveData() {
    for (let i = 1; i < environment.noOfYears; i++) {
      this.years.push((new Date().getFullYear() - i).toString());
    }
    this.selectedValues.year = this.years[0];
    this.getMonthAndStatements();
  }

  getCaasData() {
    const response = this.caasService.getCaasData();
    if (response) {
      this.statementsContent = response.Data.statements ? response.Data.statements.content : {
        brokerageNotice: '',
        transactionHistoryDesc: '',
        footerTransactionHistory: '',
        notesAndDisclosures: '',
        browserTitle: '',
        tabLabels: '',
        customReportLink: '',
        title: '',
        availabilityNotice: '',
        disclaimer: '',
        buttonLabel: '',
        dropdownOptions: { 'stmts-dropdown-option01': { 'optionLabel': '', 'optionUrl': '' } },
        tabOptions: { 'tab-option01': { 'optionLabel': '', 'optionUrl': '' } },
        apiErrorBody: '',
        apiErrorHeading: ''
      };
    }
    this.errorBannerConfig.heading = '';
    this.errorBannerConfig.body = '';
    this.contentBody = this.statementsContent.apiErrorBody
    this.viewDropdownContent = Object.keys(this.statementsContent.dropdownOptions).sort().map(key =>
    ({
      'labelText': this.statementsContent.dropdownOptions[key].optionLabel,
      'id': this.statementsContent.dropdownOptions[key].optionUrl ? this.statementsContent.dropdownOptions[key].optionUrl : '0'
    })
    );
    this.tetriaryNavigationOptions = Object.keys(this.statementsContent.tabOptions).sort().map(key =>
    ({
      'optionLabel': this.statementsContent.tabOptions[key].optionLabel,
      'optionUrl': this.statementsContent.tabOptions[key].optionUrl
    })
    );
  }

  async getMonthAndStatements() {
    try {
      this.isLoaderIndicator = true;
      this.showMoreIndex = 0;
      this.months = [ALL_MONTHS];
      this.selectedMonth = ALL_MONTHS;
      this.month = ALL_MONTHS;
      this.filterOn = false;
      this.tableOverlayShow = false;
      const newStatementList = await this.getStatement(this.selectedValues);
      this.statementsList = newStatementList.statements;

      if (this.statementsList.length === 0) {
        this.shouldShowErrorBannerForNoData = true;
      } else {
        this.shouldShowErrorBannerForNoData = false;
      }

      // sort by date
      this.statementsList.sort((a, b) =>
        moment(a.endDate) > moment(b.endDate) ? -1 : moment(a.endDate) < moment(b.endDate) ? 1 : 0
      );

      this.dateSortingState = 'descending';
      this.accountSortingState = 'none';



      this.statementsList = this.statementsList.map((stmtObj) => {
        stmtObj.statementDescription = stmtObj.statementDescription === null ? '' : stmtObj.statementDescription
        stmtObj.endDate = moment(stmtObj.endDate).format('MM/DD/YYYY')
        const check = moment(stmtObj.endDate, 'MM/DD/YYYY');
        const currentMonth = check.format('MMMM');
        stmtObj.month = currentMonth;
        this.months.push(currentMonth);
        return stmtObj
      });

      // sort by description where dates match
      this.statementsList.sort((a, b) =>
        a.endDate === b.endDate ? a.statementDescription.localeCompare(b.statementDescription) : 0
      );

      this.masterStatementsList = this.statementsList;
      this.showMoreStatements();

      this.months = [...new Set(this.months)];
      this.months.sort(function (a, b) {
        return environment.allMonths.indexOf(a.toUpperCase()) - environment.allMonths.indexOf(b.toUpperCase());
      });

      this.isLoaderIndicator = false;
    } catch (err: any) {
      this.shouldShowErrorBannerForNoData = true;
      this.statementFetchNoError = false;
      this.hideShowMore = true;
      this.statementsList = [];
      this.masterStatementsList = [];
      this.filteredMasterStatementList = [];
      this.loggerService.error({
        message: err,
        app: PKG.deployment.appPrefix || 'UJG',
        logCode: 'Error Code'
      })
      this.isLoaderIndicator = false;
    }
  }

  showMoreStatements() {
    this.showMoreIndex++;
    const totalRecordsToShow = this.showMoreIndex * this.noOfRecordsInTable;
    const masterList = this.filterOn ? this.filteredMasterStatementList : this.masterStatementsList;

    if (totalRecordsToShow < masterList.length) {
      this.statementsList = masterList.slice(0, totalRecordsToShow);
      this.hideShowMore = false;
    } else {
      this.statementsList = masterList;
      this.hideShowMore = true;
    }
    setTimeout(() => {
      this.checkLeftArrowActiveActions('SHOW_MORE');
    }, 300);
    if (this.showMoreKeyboardFlag) {
      const newRowDownloadLinkCounter = ((this.showMoreIndex - 1) * this.noOfRecordsInTable);
      this.changeDetector.detectChanges();
      document.getElementById('download-icon-' + newRowDownloadLinkCounter)?.focus();
      this.showMoreKeyboardFlag = false;
    }
  }

  sortColumn(column) {
    const masterList = this.filterOn ? this.filteredMasterStatementList : this.masterStatementsList;
    const totalRecordsToShow = this.showMoreIndex * this.noOfRecordsInTable;
    if (column === 'date') {
      this.descendingsort(masterList);
    } else {
      this.aescendingsort(masterList);
    }
    this.statementsList = masterList.slice(0, totalRecordsToShow);
  }

  private aescendingsort(masterList: Stmtobject[]) {
    if (this.accountSortingState === 'ascending') {
      this.accountSortingState = 'descending';
      this.dateSortingState = 'none';
      masterList.sort((a, b) => b.statementDescription.localeCompare(a.statementDescription)
      );
      this.statementsList = [...masterList];
    } else {
      this.accountSortingState = 'ascending';
      this.dateSortingState = 'none';
      masterList.sort((a, b) => a.statementDescription.localeCompare(b.statementDescription)
      );
      this.statementsList = [...masterList];
    }
    setTimeout(() => {
      this.checkLeftArrowActiveActions('SORT');
    }, 300);
  }

  private descendingsort(masterList: Stmtobject[]) {
    if (this.dateSortingState === 'descending') {
      this.dateSortingState = 'ascending';
      this.accountSortingState = 'none';
      masterList.sort((a, b) => moment(a.endDate) < moment(b.endDate) ? -1 : moment(a.endDate) > moment(b.endDate) ? 1 : 0
      );
      this.statementsList = [...masterList];
    } else {
      this.dateSortingState = 'descending';
      this.accountSortingState = 'none';
      masterList.sort((a, b) => moment(a.endDate) > moment(b.endDate) ? -1 : moment(a.endDate) < moment(b.endDate) ? 1 : 0
      );
      this.statementsList = [...masterList];
    }
  }

  async getSelectedValue(e: any, type: string) {
    if (type === 'MONTH') {
      if (this.month !== this.selectedMonth) {
        this.tableOverlayShow = true;
      } else {
        this.tableOverlayShow = false;
      }
    } else {
      this.selectedValues.year = e.target.value;
      this.checkLeftArrowActiveActions('YEAR_CHANGE');
      this.getMonthAndStatements();
    }
  }

  async searchStatement() {
    this.selectedMonth = this.month;
    this.tableOverlayShow = false;
    if (this.month.toUpperCase() !== 'ALL MONTHS') {
      this.showMoreIndex = 0;
      this.statementsList = this.masterStatementsList.filter(stmt => stmt.month === this.month);
      this.filteredMasterStatementList = this.statementsList;
      this.filterOn = true;
    } else {
      this.showMoreIndex = this.showMoreIndex - 1;
      this.statementsList = this.masterStatementsList;
      this.filterOn = false;
      this.filteredMasterStatementList = [];
    }
    this.showMoreStatements();
  }

  resetFilter() {
    this.month = this.selectedMonth;
    this.tableOverlayShow = false;
  }

  private getStatement(searchData: Selectedvalueobject): Promise<StmtResponse> {
    return this.statementService.getStatement(searchData).toPromise();
  }

  // For Mobile view
  toggleAccountdownload(value: string) {
    this.tableheading = value
    if (value === content.statementsContent.tableSection.viewDownloadColumnLabel) {
      this.tableheading = content.statementsContent.tableSection.viewDownloadColumnLabel;
      this.showaccountdata = false;
      this.showpdfdata = false;
      this.showpdfdataresponsive = true;
      this.secondaryvariant = 'primary';
      this.primaryvariant = 'secondary';
    } else {
      this.tableheading = content.statementsContent.tableSection.accountColumnLabel;
      this.showaccountdata = true;
      this.showpdfdata = true;
      this.showpdfdataresponsive = false;
      this.secondaryvariant = 'secondary';
      this.primaryvariant = 'primary';
    }
    this.modal.closeDialogModal();
  }

  downloadpdf(statementId: string,event, rowData: Stmtobject) {
    let startTime = moment(new Date());
    let distinctDownloadId = moment(rowData.endDate).format("X")+"-"+rowData.statementNumber;
    // let downloadFilename = 'statement-'+rowData.statementNumber+'-'+rowData.endDate+'.pdf';
    let downloadFilename = 'statement.pdf';
    this.isLoaderIndicator = true;
    this.statementService.downloadPdf(statementId).subscribe((data: Blob) => {
      this.isLoaderIndicator = false;
      this.downloadFile(data, downloadFilename);
      let endTime = moment(new Date());
      let duration = moment.duration(endTime.diff(startTime)).asMilliseconds();
      this.windowRef.nativeWindow.dataLayer = this.windowRef.nativeWindow.dataLayer || [];
      // console.log('spoid =>',this.windowRef.nativeWindow.spoid, rowData, moment(rowData.endDate).format("X"));
      // console.log("diff",moment.duration(endTime.diff(startTime)).asMilliseconds());

      this.windowRef.nativeWindow.dataLayer.push({
        "event" : "callToAction", //e111
        "callToAction": {
          "location": "View Statement", //v103
          "ctaName": "Download PDF", //v104
          "ctaType": "link", //v111
          "ctaValue": "Download PDF"+":"+distinctDownloadId+":statements"+":duration:"+duration,
          "duration": duration
           },
           fireTrackLink: {
               'fireTrackLink': 'true'
           }
    });

    this.windowRef.nativeWindow.dataLayer.push({
        "event": "contentDownloaded", //e24
        "linkInfo": {
           "fileName": "pdf:"+distinctDownloadId+":statement"+":duration:"+duration,//<fileformat>:<siteSection2>:<reportName> //v70
           "fileLocation": distinctDownloadId, //<fileLocation> //v63
           "duration": duration
      }
    });

    }, (error) => {
      let failEndTime = moment(new Date());
      let failDuration = moment.duration(failEndTime.diff(startTime)).asMilliseconds();
      this.windowRef.nativeWindow.dataLayer = this.windowRef.nativeWindow.dataLayer || [];
      this.windowRef.nativeWindow.dataLayer.push({
        "event" : "callToAction", //e111
        "callToAction": {
          "location": "Error View Statement", //v103
          "ctaName": "Error Download PDF", //v104
          "ctaType": "link", //v111
          "ctaValue": "Error Download PDF"+":"+distinctDownloadId+":statements"+":duration:"+failDuration,
          "duration": failDuration
           },
           fireTrackLink: {
               'fireTrackLink': 'true'
           }
    });
      this.errorModal.openModalDialog(event);
      this.isLoaderIndicator = false;
      console.error(error);
    })
  }

  downloadFile(response, fileName: string) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    const blob = new Blob([response], { type: "application/pdf" });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);
  }

  viewRedirect(event) {
    if (event.activeItems[0] !== '0') {
      window.open(event.activeItems[0], "_self");
    }
  }

  keyHandler(event, link, rowData: Stmtobject) {
    event.stopPropagation();
    this.downloadpdf(link,event, rowData);
  }

  keyHandlerShowMore(event) {
    this.showMoreKeyboardFlag = true;
    event.stopPropagation();
    this.showMoreStatements();
  }

  onScroll(): void {
    const tableView = this.elRef.nativeElement;
    if (tableView) {
      // table is scrolled all the way to the left or right
      // determine if left/right scroll button/gradients need to be removed
      if (tableView.scrollLeft + tableView.offsetWidth >= tableView.scrollWidth) {
        // scroll is all the way to the right
        this.addLeftScrollButton(tableView);
        this.addLeftGradient(tableView);
      } else if (tableView.scrollLeft === 0) {
        // scroll is all the way to the left
        this.removeLeftScrollButton(tableView);
        this.removeLeftGradient(tableView);
      } else {
        // scroll is in the middle
        this.addLeftScrollButton(tableView);
        this.addLeftGradient(tableView);
      }
    }
  }

  addLeftGradient(element: HTMLElement) {
    const thElements = element.querySelectorAll('th.sticky-left');
    thElements.forEach((item) => item.classList.add(GRADIENT_LEFT));

    const tdElements = element.querySelectorAll('td.sticky-left');
    tdElements.forEach((item) => item.classList.add(GRADIENT_LEFT));
  }

  addLeftScrollButton(element: HTMLElement) {
    const buttonView = element.querySelector('[data-id="scroll_left"]') as HTMLElement;
    buttonView.hidden = false;
  }

  removeLeftGradient(element: HTMLElement) {
    const thElements = element.querySelectorAll('th.sticky-left');
    thElements.forEach((item) => item.classList.remove(GRADIENT_LEFT));

    const tdElements = element.querySelectorAll('td.sticky-left');
    tdElements.forEach((item) => item.classList.remove(GRADIENT_LEFT));
  }

  removeLeftScrollButton(element: HTMLElement) {
    const buttonView = element.querySelector('[data-id="scroll_left"]') as HTMLElement;
    buttonView.hidden = true;
  }

  scrollRight(): void {
    this.elRef.nativeElement.scrollLeft += SCROLLVALUE;
  }

  scrollLeft(): void {
    this.elRef.nativeElement.scrollLeft -= SCROLLVALUE;
  }

  checkLeftArrowActiveActions(action?: string) {
    const el = this.elRef.nativeElement.querySelector('[data-id="scroll_left"]') as HTMLElement;
    if (el && !el.hidden) {
      switch (action) {
        case 'YEAR_CHANGE': {
          this.scrollLeft();
          break;
        }
        case 'SHOW_MORE': {
          this.addLeftGradient(this.elRef.nativeElement);
          break;
        }
        case 'SORT': {
          this.addLeftGradient(this.elRef.nativeElement);
        }
      }
    }
  }
}
