import { AfterViewInit, Directive, ElementRef } from '@angular/core';
// import { TableUtils as util } from 'app/utils';
import { fromEvent, merge, of, Subject } from 'rxjs';
import { debounceTime, filter, takeUntil, tap } from 'rxjs/operators';

const LG_BREAKPOINT = 481;

@Directive({ selector: '[stmtStickyHeader]' })
export class StickyHeaderDirective implements AfterViewInit {
  private destroy$ = new Subject<void>();

  constructor(private elRef: ElementRef) {}

  public ngAfterViewInit(): void {
    const elToObserve = this.elRef.nativeElement.parentElement;

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          fromEvent(window, 'scroll')
            .pipe(
              filter(() => !!this.elRef),
              takeUntil(this.destroy$),
              tap(() => this.headerReplaceY(this.elRef)),
            )
            .subscribe();

          merge(fromEvent(window, 'resize'), of(null))
            .pipe(
              takeUntil(this.destroy$),
              debounceTime(400),
              tap(() => this.headerReplaceY(this.elRef)),
            )
            .subscribe();
        } else {
          this.destroy$.next();
          this.elRef.nativeElement.style.transform = '';
        }
      });
    });

    observer.observe(elToObserve);
  }

  headerReplaceY(elRef: ElementRef): void {
    const { top, bottom, height } = elRef.nativeElement.parentElement.getBoundingClientRect();
    if (top < 0 && window.innerWidth < LG_BREAKPOINT) {
      const headerHeight = elRef.nativeElement.getBoundingClientRect().height;
      elRef.nativeElement.style.transform =
        bottom > headerHeight ? `translateY(${height - bottom}px)` : `translateY(${height - headerHeight}px)`;
    } else {
      elRef.nativeElement.style.transform = '';
    }
  }
}
