<title class="greeting_info_a11y_page_header" tabindex="-1">
  {{statementsContent.browserTitle}}
</title>
<ss-lib-page-layout>
  <div inner>
    <div class="row">
      <div class="col">
        <secondary-nav-widget active-item="ActivityTab" ></secondary-nav-widget>
        <div class="tertiary-nav" data-testid="tertiary-nav">
          <div class="tertiary-nav__customize_account">
            <a c11n-link [attr.href]="downloadLinkUrl" data-testid="download-center-link" variant="secondary-independent"
              fontWeight="normal" [attr.aria-label]="'Download center account information'">
              <span class="c11n-link__text">{{ downloadLinkLabel }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <app-tetriary-navigation [options]="tetriaryNavigationOptions" activeLink="STATEMENTS">
        </app-tetriary-navigation>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <c11n-banner *ngIf="shouldShowErrorBanner" [size]="errorBannerConfig.size" [heading]="errorBannerConfig.heading"
          [closable]="errorBannerConfig.closable" [collapsible]="errorBannerConfig.collapsible"
          [type]="errorBannerConfig.type">
          <div class="c11n-text-md--crop">{{errorBannerConfig.body}}</div>
        </c11n-banner>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="transaction-summary">
          <div id="transaction-history-summary-section">
            <div class="main-div">
              <!-- <c11n-spinner *ngIf="isLoaderIndicator" size="md" [isOnDark]="false" spinnerStatus="Loading..."></c11n-spinner> -->

              <div class="line-copy"></div>
              <div class="container-fluid p-0">

                <div class="header-div mt-3">
                  <div class="row">
                    <div class="col-12 col-md-7 col-lg-8 c11n-grid-story__cell">
                      <h1 class="sub-heading">{{statementsContent.title}}</h1>
                    </div>
                    <div class="col-12 col-md-5 col-lg-4 c11n-grid-story__cell view-select-wrapper mt-3 mt-md-0">

                      <div class="col-4 float-left-class mt-4 view-span-wrapper">
                        <span
                          class="view-select-label me-3">{{content.statementsContent.viewDropdownContent.label}}</span>
                      </div>

                      <div class="col-8 float-right-class">
                        <div [ngStyle]="{'maxWidth': '14rem', 'width': '100%', 'text-align': 'left'}"
                          class="view-select-div float-right-class">
                          <label id="selectId-description" style="display: none;">select view</label>
                          <c11n-dropdown [menuData]="dropdownMenuOptions" labelText=""
                            (stateChange)="viewRedirect($event)" placeholderText="Select view" variant="basic"
                            class="selectViewDropdown" aria-label="select view"></c11n-dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="description-div mt-4 col-md-12 c11n-grid-story__cell"
                    [innerHTML]="statementsContent.transactionHistoryDesc"></div>
                  <div class="availability-notice mt-4 col-md-12 c11n-grid-story__cell"
                    [innerHTML]="statementsContent.availabilityNotice"></div>
                </div>

                <div class="row mt-3">
                  <div class="alert alert-danger alert-dismissible fade show month-year-error" role="alert"
                    *ngIf="errorStateYear || errorStateMonth">
                    <p *ngIf="errorStateYear"><strong>Colud not fetch year!</strong> Error status: {{ errorTextYear }}
                    </p>
                    <p *ngIf="errorStateMonth"><strong>Colud not fetch month!</strong> Error status: {{ errorTextMonth
                      }}</p>
                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                  </div>
                  <div class="col-3 c11n-grid-story__cell d-xs-block d-md-none pt-3">
                    <p>{{content.statementsContent.filterSection.filterLabel}}</p>
                  </div>
                  <div class="col-8 col-md-12 c11n-grid-story__cell filter-div">
                    <!-- <div class="filter-div mt-4"> -->
                    <div [ngStyle]="{'maxWidth': '16rem'}"
                      class="d-inline-block me-4 col-12 col-md-2 c11n-grid-story__cell">
                      <!-- <label id="select-year-id-description" style="display: none;">select year</label> -->
                      <c11n-select labelText="" hintText="" (change)="getSelectedValue($event, 'YEAR')"
                        class="hide-select-label-and-error">
                        <select c11nSelectInput id="select-year-id" class="c11n-select__box__select"
                          aria-label="select year">
                          <option *ngFor="let year of years;let i=index" [value]="year">{{ year }}</option>
                        </select>
                      </c11n-select>
                    </div>

                    <div [ngStyle]="{'maxWidth': '16rem'}"
                      class="d-inline-block me-4 col-12 col-md-3 c11n-grid-story__cell">
                      <label id="select-month-id-description" style="display: none;">select month</label>
                      <c11n-select labelText="" hintText="" (change)="getSelectedValue($event, 'MONTH')"
                        class="hide-select-label-and-error">
                        <select c11nSelectInput id="select-month-id" class="c11n-select__box__select"
                          aria-label="select month" [(ngModel)]="month">
                          <option *ngFor="let month of months;let i=index" [value]="month">{{ month }}</option>
                        </select>
                      </c11n-select>
                    </div>

                    <div class="d-inline-block col-12 col-md-3 c11n-grid-story__cell">
                      <c11n-button buttonType="button" size="medium" variant="primary"
                        class="d-inline-block update-button update-button-stmts-table"
                        [labelText]="statementsContent.buttonLabel" (clickEvent)="searchStatement()"></c11n-button>
                    </div>
                  </div>

                </div>


                <div class="row">
                  <div class="col-md-12 c11n-grid-story__cell">

                    <c11n-spinner *ngIf="isLoaderIndicator" class="spinner-on-screen" size="medium" [isOnDark]="false"
                      colorMode="on-light" spinnerStatus="Loading...">
                    </c11n-spinner>
                    <div class="for-scroll" #contentElement data-id='scrollable_table' (scroll)="onScroll()">
                      <table id="statements-list-table"
                        class="mt-3 c11n-table c11n-table--small c11n-table--sticky-th-horizontal c11n-table--sticky-th-vertical"
                        data-cy="recent-trans-table" data-vui-sticky-elements-parent="">

                        <div id="overlay" *ngIf="tableOverlayShow">
                          <div class="d-inline-block col-12 col-md-1 col-sm-12 c11n-grid-story__cell">
                            <c11n-button buttonType="button" size="medium" variant="primary" class="resetBtnClass"
                              labelText="Reset" (clickEvent)="resetFilter()">
                            </c11n-button>
                          </div>
                        </div>

                        <thead stmtStickyHeader>
                          <tr class="c11n-table__tr">

                            <th scope="col" class="c11n-table__thead-th sticky-left" [attr.aria-sort]="dateSortingState"
                              [ngClass]="[dateSortingState, dateSortingState !== 'none' ? 'c11n-table__thead-th--active-sort': '']">
                              <button type="button" (click)="sortColumn('date')"
                                class="c11n-link c11n-link--secondary c11n-link--icon c11n-link--normal"
                                title="Sort by date" [attr.aria-label]="'Sorted ' + dateSortingState"
                                style="width: 100%;">
                                <span class="c11n-link__content">
                                  <span id="type"
                                    class="c11n-link__text type-header">{{content.statementsContent.tableSection.dateColumnLabel}}</span>
                                  <svg viewBox="0 0 8 14" focusable="false" aria-hidden="true">
                                    <use xlink:href="#c11n-table-sort-arrow"></use>
                                    <use xlink:href="#c11n-table-sort-arrow"></use>
                                  </svg>
                                </span>
                              </button>
                              <div data-id='scroll_left' class='scroll-left hide-arrows-mobile' hidden (click)='scrollLeft()'>
                                <c11n-icon [name]="'caret-left'" [size]="'small'"></c11n-icon>
                              </div>
                            </th>

                            <th scope="col" class="c11n-table__thead-th account-header-width" [attr.aria-sort]="accountSortingState"
                              [ngClass]="[accountSortingState, accountSortingState !== 'none' ? 'c11n-table__thead-th--active-sort': '']"
                              style="text-align: left;">
                              <button type="button" (click)="sortColumn('account')"
                                class="c11n-link c11n-link--secondary c11n-link--icon c11n-link--normal"
                                title="Sort by account" [attr.aria-label]="'Sorted ' + accountSortingState"
                                style="width: 100%;text-align: left;">
                                <span class="c11n-link__content">
                                  <span id="account"
                                    class="c11n-link__text type-header">{{content.statementsContent.tableSection.accountColumnLabel}}</span>
                                  <svg viewBox="0 0 8 14" focusable="false" aria-hidden="true">
                                    <use xlink:href="#c11n-table-sort-arrow" class=""></use>
                                    <use xlink:href="#c11n-table-sort-arrow" class=""></use>
                                  </svg>
                                </span>
                              </button>

                              <!-- <button type="button"
                              class="c11n-link c11n-link--secondary c11n-link--icon c11n-link--normal accountresponsiveheader"
                              title="Sort by account" aria-label="sort by account"
                              (click)="chooseColumnModal.openModalDialog($event)">
                              <span class="c11n-link__content account_align">
                                <span id="accountmobileview" class="c11n-link__text">{{tableheading}}
                                </span>
                              </span>
                            </button> -->

                              <!-- <a class="c11n-link c11n-link--primary c11n-link--regular accountresponsiveheader"
                              data-toggle="dropdown" c11nModalButtonTrigger
                              (click)="chooseColumnModal.openModalDialog($event)" style="left: -16px; top: 2px;">
                              <svg aria-hidden="true" focusable="false"
                                class="c11n-caret-down-small error-icon-style caret_symbol_align">
                                <use xlink:href="#caret-down-small"></use>
                              </svg>
                            </a> -->

                            </th>
                            <!--responsivedownload class is For Mobile view-->
                            <th scope="col" class="c11n-table__thead-th c11n-table__thead-th--align-left">
                              <span id="view-download" class="view-download" title="view/download" role="text"
                                aria-label="view/download">
                                {{content.statementsContent.tableSection.viewDownloadColumnLabel}} </span>
                            </th>

                            <!-- <th scope="col" class="c11n-table__thead-th c11n-table__thead-th--align-left">
                              <span id="view-download" class="view-download" title="view/download" role="text"
                                aria-label="view/download">
                                {{content.statementsContent.tableSection.viewDownloadColumnLabel}} </span>
                            </th> -->
                            <th class="sticky-right gradient-right">
                              <div data-id="scroll_right" class="scroll-button scroll-right" (click)="scrollRight()">
                                <c11n-icon [name]="'caret-right'" [size]="'small'"></c11n-icon>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngIf='shouldShowErrorBannerForNoData'>
                            <td [colSpan]='4'>
                              <div class="c11n-banner c11n-banner--small c11n-banner--error">
                                <div class="c11n-banner__leading-icon">
                                  <svg class="c11n-error-small error-icon-style" role="img" aria-hidden="false"
                                    focusable="true">
                                    <title>error</title>
                                    <use xlink:href="#error-small"></use>
                                  </svg>
                                </div>

                                <div class="c11n-banner__main">
                                  <div class="c11n-banner__content">
                                    <div class="c11n-banner__body">
                                      <div class="c11n-text-md--crop">{{ contentBody }}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr class="c11n-table__tr" *ngFor="let statement of statementsList; let i = index">
                            <td class="recent-transaction-type c11n-table__td c11n-table__td--align-left sticky-left">
                              {{statement.endDate}}</td>
                            <td *ngIf="showaccountdata"
                              class="recent-transaction-type c11n-table__td c11n-table__td--align-left"
                              [innerHTML]="statement.statementDescription">
                            </td>
                            <!--responsivedownload class is For Mobile view-->
                            <td *ngIf="showpdfdata"
                              class="ps-5 recent-transaction-type c11n-table__td c11n-table__td--align-left">
                              <c11n-icon _ngcontent-bii-c24 name="download" class="responsive_pdf_icon" tabIndex="0"
                                title="Pdf download icon" [attr.id]="'download-icon-' + i" focusable="true"
                                attr.aria-label="Download a pdf statement generated on {{statement.endDate  | date: 'longDate'}} with description {{statement.statementDescription}}"
                                role="button" (click)="downloadpdf(statement.statementId, $event, statement)"
                                (keydown.space)="keyHandler($event, statement.statementId, statement);"
                                (keydown.enter)="keyHandler($event, statement.statementId, statement);">
                                <svg aria-hidden="true" class="c11n-download-small">
                                  <use xlink:href="#download-small"></use>
                                </svg>
                              </c11n-icon>

                            </td>
                            <!-- For Mobile view 
                            <td *ngIf="showpdfdataresponsive"
                              class="ps-5 recent-transaction-type c11n-table__td c11n-table__td ">
                              <c11n-icon _ngcontent-bii-c24 name="download" class="responsive_pdf_icon_mobile"
                                tabIndex="0" (click)="downloadpdf(statement.statementId, $event)"
                                (keydown.space)="keyHandler($event, statement.statementId);"
                                (keydown.enter)="keyHandler($event, statement.statementId);">
                                <svg class="c11n-download-small">
                                  <use xlink:href="#download-small"></use>
                                </svg>
                              </c11n-icon>
                            </td> -->
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <a class="c11n-link c11n-link--secondary show-more-link c11n-text-sm c11n-link--independent c11n-link--normal"
                      (click)="showMoreStatements()" [hidden]="hideShowMore"
                      [ngClass]="tableOverlayShow ? 'disabled-link' : '' " id="show-more" tabIndex="0"
                      (keydown.space)="keyHandlerShowMore($event);" (keydown.enter)="keyHandlerShowMore($event);">
                      <span class="c11n-link__content">Show more</span>
                    </a>
                    <p class="p-5 text-center statement-table-error" [hidden]="statementFetchNoError">
                      {{statementFetchErrorText}}
                    </p>
                  </div>
                </div>

                <div class="bottom-paragraph-section mt-5">
                  <h1 class="sub-heading" [innerHTML]="statementsContent.footerTransactionHistory"></h1>
                  <div class="custom-report-link mt-2" [innerHTML]="statementsContent.customReportLink"></div>
                  <div class="disclosure-link link link-decoration mt-4"
                    [innerHTML]="statementsContent.notesAndDisclosures"></div>
                  <div class="disclosure-link link link-decoration mt-2"
                    [innerHTML]="statementsContent.brokerageNotice"></div>
                </div>
                <div class="disclaimer mb-4" [innerHTML]="statementsContent.disclaimer"></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ss-lib-page-layout>

<!-- <div style="display: none">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" width="1000" height="1000">
    <symbol id="error-small" viewBox="0 0 14 14">
      <path
        d="M13.541,5.884,8.109.452a1.605,1.605,0,0,0-2.218,0L.459,5.884a1.568,1.568,0,0,0,0,2.217l5.432,5.432a1.567,1.567,0,0,0,2.218,0L13.541,8.1a1.568,1.568,0,0,0,0-2.217ZM6.25,3.493a.75.75,0,0,1,1.5,0v3a.75.75,0,0,1-1.5,0Zm.75,7a1,1,0,1,1,1-1A1,1,0,0,1,7,10.493Z">
      </path>
    </symbol>
  </svg>
</div> -->
<!-- For Mobile view-->
<c11n-modal-dialog #chooseColumnModal [headingText]='statementsContent.title'>
  <span c11n-modal-dialog-body>
    <ng-container>
      <div>
        <c11n-button buttonType="button" size="medium" [variant]="primaryvariant" class="d-inline-block update-button"
          [labelText]=content.statementsContent.tableSection.accountColumnLabel
          (clickEvent)="toggleAccountdownload(content.statementsContent.tableSection.accountColumnLabel)"
          c11nModalButtonPrimary></c11n-button>
        <c11n-button buttonType="button" size="medium" [variant]="secondaryvariant"
          class="d-inline-block update-button mt-1"
          [labelText]=content.statementsContent.tableSection.viewDownloadColumnLabel
          (clickEvent)="toggleAccountdownload(content.statementsContent.tableSection.viewDownloadColumnLabel)"
          c11nModalButtonPrimary>
        </c11n-button>
      </div>
    </ng-container>


  </span>
</c11n-modal-dialog>

<c11n-modal-dialog #errorModal [headingText]="errorPdfDownload" class="error-modal">
  <span c11n-modal-dialog-body>
    <ng-container>
      <div class="row">
        <span>We apologize for any inconvenience. You'll need to try again later.</span>
        <c11n-button style="display: none;" c11nModalButtonPrimary></c11n-button>
      </div>
    </ng-container>
  </span>
</c11n-modal-dialog>