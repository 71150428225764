export const content = {
  contentData: {
    transactionHistory: "Transaction history",
    orderStatus: "Order status",
    statements: "Statements",
    confirmations: "Confirmations",
    api_error_heading: "Data unavailable",
    api_error_body: "Our system is temporarily unable to display some of your account information." + 
    "We apologize for the inconvenience and appreciate your patience while we fix these issues. " +
      "Please refresh in a few minutes or choose another page."
  },
  linksContent: {
    secureMessage: {
      url: 'https://personal.vanguard.com/us/SMCInformationCenter',
    },
    transactions: {
      url: 'https://transactions.webe.vanguard.com/',
    },
    orderStatus: {
      url: 'http://prfoedesktop.vanguard.com:54080/web/cfv/order-status/',
    },
    confirmations: {
      url: 'https://confirmations.webe.vanguard.com/',
    },
    statements: {
      url: 'https://statements.webe.vanguard.com/',
    }
  },
  statementsContent: {
    viewDropdownContent: {
      label: "View:",
      viewOptions: [
        {
          link: "",
          linkName: "Select view"
        },
        {
          link: "https://satpersonal1.vanguard.com/ffa-tax-webapp/",
          linkName: "Tax forms"
        },
        {
          link: "https://prfpersonal.vanguard.com:53443/us/AddrViewController",
          linkName: "Duplicate statements and transactions confirmations"
        },
        {
          link: "https://prfpersonal.vanguard.com:53443/#",
          linkName: "Create account valuation report"
        },
        {
          link: "https://prfpersonal.vanguard.com:53443/#",
          linkName: "Create transaction history report"
        }
      ]
    },
    filterSection: {
      filterLabel: "Sort:"
    },
    tableSection: {
      dateColumnLabel: "Date",
      accountColumnLabel: "Account",
      viewDownloadColumnLabel: "View/download"
    }
  },
  caasContent: {
    "LatestModified": 1649123760993,
    "Results": 2,
    "Error": "",
    "Data": {
      "download_center": {
        "metadata": {
          "path": "/content-fragments/caas/statements_confirms/download-center",
          "variation": "master"
        },
        "content": {
          "optionLabel": "Download center",
          "optionUrl": "https://satpersonal.vanguard.com:55443/us/OfxWelcome"
        }
      },
      "statements": {
        "metadata": {
          "path": "/content-fragments/caas/statements_confirms/statements",
          "variation": "master"
        },
        "content": {
          "footerTransactionHistory": "<p>Get all the transaction history you need-in one document.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>\n",
          "notesAndDisclosures": "<p><a href=\"https://personal.vanguard.com/pdf/CFVBSStatementBacker.pdf?cbdForceDomain=false\""+
          "target=\"_blank\" rel=\"nofollow\">View statement notes &amp; disclousers for assets held with Vanguard Brokerage</a></p>\n",
          "browserTitle": "Statements",
          "title": "All accounts",
          "tabOptions": {
            "tab-option-02": {
              "optionLabel": "Order status",
              "optionUrl": "https://personal.vanguard.com/us/OrderStatus"
            },
            "tab-option-03": {
              "optionLabel": "Confirmations",
              "optionUrl": "https://confirmations.web.vanguard.com/"
            },
            "tab-option-01": {
              "optionLabel": "Transaction history",
              "optionUrl": "https://transactions.web.vanguard.com/"
            },
            "tab-option-04": {
              "optionLabel": "Statements"
            }
          },
          "buttonLabel": "Update Table",
          "dropdownOptions": {
            "stmts-dropdown-option05": {
              "optionLabel": "Create transaction history report",
              "optionUrl": "https://personal.vanguard.com/web/c1/account-activity-report/filter"
            },
            "stmts-dropdown-option01": {
              "optionLabel": "Select view"
            },
            "stmts-dropdown-option02": {
              "optionLabel": "Tax Forms",
              "optionUrl": "https://personal1.vanguard.com/ffa-tax-webapp/tax-center"
            },
            "stmts-dropdown-option03": {
              "optionLabel": "Duplicate Statements and transaction confirmations",
              "optionUrl": "https://personal.vanguard.com/us/AddrViewController"
            },
            "stmts-dropdown-option04": {
              "optionLabel": "Create account valuation report",
              "optionUrl": "https://personal.vanguard.com/us/faces/web/accountvaluationtool/view/ViewExistingReports.xhtml"
            }
          },
          "brokerageNotice": "<p><a href=\"https://personal.vanguard.com/pdf/vbsan.pdf?cbdForceDomain=false\" target=\"_blank\">"+
          "View Vanguard Brokerage Services Annual Notice</a></p>\n",
          "transactionHistoryDesc": "<p><a href=\"https://personal.vanguard.com/web/c1/account-activity-report/filter\" target=\"_self\">"+
          "Try our new custom report tool</a> to get as much transaction history as you need - all in one document.</p>\n",
          "customReportLink": "<p><a rel=\"nofollow\" href=\"https://personal.vanguard.com/web/c1/account-activity-report/filter\" target=\"_blank\">"+
          "Try our new custom report tool</a>. It's easy to use, and you can save or print your report for your records.</p>\n",
          "apiErrorBody": "There are no statements available for this time period."+
          "Please use the dropdown to make another selection.",
          "availabilityNotice": "<p>Statements are available for the current year and previous 6 years."+
          "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>\n",
          "apiErrorHeading": "Our system is temporarily unable to display some of your account information." +  
          "We apologize for the inconvenience and appreciate your patience while we fix these issues." +
          "Please refresh in a few minutes or choose another page.",
          "disclaimer": "<p>*Brokerage assets are held by Vanguard Brokerage Services, a division of Vanguard Marketing Corporation,"+ 
          "member FINRA [<a href=\"https://www.finra.org/investors#/\">"+
          "https://www.finra.org/investors#/</a>]&nbsp;"+
          "and SIPC[<a href=\"https://www.sipc.org/\">https://www.sipc.org/</a>]</p>\n"
        }
      }
    }
  }
};
