/*import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { environment } from '../../../environments/environment';
export abstract class Logger {
  debug: any
  info: any
  warn: any
  error: any
}

@Injectable({
  providedIn: 'root'
})
export class LoggerService implements Logger, OnDestroy{
  loggingEndpoint: string;
  public uuid: string;
  loggingEndpointSubscription: Subscription

  constructor(private httpClient: HttpClient) {
    // this.loggingEndpoint = location.prepareExternalUrl('client-log/');
    this.loggingEndpoint = `${environment.STATEMENTS.BASE_URL}${environment.STATEMENTS.LOGGER}`;
    this.uuid = `HDV-${uuidv4()}`;
  }

  public getUUID(){
    return this.uuid;
  }

  private log(data: Object) {
    const options = {
      body: data,
      headers: new HttpHeaders().set('Content-type', 'application/json')
    };
    this.httpClient.request('POST', this.loggingEndpoint, options).subscribe();
  }

  public debug(message: string, code: any, accountId?: string) {
    this.log({
      level: 'debug',
      code: code,
      message: message,
      accountId: accountId,
      uuid: this.uuid
    });
  }

  public info(message: string, code: any, accountId?: string) {
    this.log({
      level: 'info',
      code: code,
      message: message,
      accountId: accountId,
      uuid: this.uuid
    });
  }

  public warn(message: string, code: any, accountId?: string) {
    this.log({
      level: 'warn',
      code: code,
      message: message,
      accountId: accountId,
      uuid: this.uuid
    });
  }

  public error(message: string, code: any, accountId?: string) {
    this.log({
      level: 'error',
      code: code,
      message: message,
      accountId: accountId,
      uuid: this.uuid
    });
  }

  public httpError(responseError: HttpErrorResponse, code: string) {
    let message = JSON.stringify({
      'message': responseError.message,
      'status': responseError.status,
      'url': responseError.url
    });

    this.log({
      level: 'error',
      code: code,
      message: message,
      accountId: null,
      uuid: this.uuid
    });
  }

  ngOnDestroy(): void {
    if(this.loggingEndpointSubscription !== undefined){
      this.loggingEndpointSubscription.unsubscribe();
    }
  }
}
*/


import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { LoggerLevel, LogMessage, Log } from './logger.model';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  loggerEndpoint: string;

  constructor(private readonly http: HttpClient, private readonly cookie: CookieService) {
    this.loggerEndpoint = `${environment.STATEMENTS.BASE_URL}${environment.STATEMENTS.LOGGER}`;
  }

  public info(message: LogMessage): void {
    this.sendLog(LoggerLevel.INFO, message);
  }

  public warn(message: LogMessage): void {
    this.sendLog(LoggerLevel.WARN, message);
  }

  public error(message: LogMessage): void {
    this.sendLog(LoggerLevel.ERROR, message);
  }

  private sendLog(level: LoggerLevel, message: LogMessage): void {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-XSRF-TOKEN': this.cookie.get('XSRF-TOKEN'),
      }),
      withCredentials: true,
    };

    this.http.post(this.loggerEndpoint, new Log(level, message), httpOptions).toPromise();
  }
}
