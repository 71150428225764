import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { StatementsObject } from '../../models/caas.model';
import { content } from '../../../content/content';


@Injectable({
  providedIn: 'root'
})
export class CaasService {
  private caasData: StatementsObject;
  constructor( private readonly http: HttpClient) {}

  getContent() {
    return this.http.get<StatementsObject>(`${environment.STATEMENTS.BASE_URL}${environment.STATEMENTS.CAAS_URL}`).pipe().toPromise().then((response) => {
      this.caasData = response;
    }).catch((err) => {
      console.log('Caas faile', err)
      this.caasData = content.caasContent;
    });
  }
  
  getCaasData(){
    return this.caasData;
  }
}
